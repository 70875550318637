import AutoBankingService from '../services/auto-banking'

const mutations = {}

const actions = {
  async registerSCB({ dispatch }, { id, data }) {
    try {
      const response = await AutoBankingService.registerScbEasyApp(id, data)
      dispatch('notifySuccess')
      return response
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async verifyScbOTP({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.verifyScbOTP(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerScbEasyNet({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerScbEasyNet(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerKBiz({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerKBiz(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerKPlus({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerKPlus(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerBayOnline({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerBayOnline(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      return false
    }
  },
  async registerTrueWallet({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerTrueWallet(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerTrueWebhook({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerTrueWebhook(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerSMS({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerSMS(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerNotify({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerNotify(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async registerPaymentGateway({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.registerPaymentGateway(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async toggleBankingStatus({ dispatch }, { id, banking }) {
    try {
      await AutoBankingService.toggleBankingStatus(id, banking)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      return false
    }
  },
  async requestKKPOtp({ dispatch }, { id, data }) {
    try {
      const response = await AutoBankingService.requestKKPOtp(id, data)
      dispatch('notifySuccess')
      return response
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async submitKKPOtp({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.submitKKPOtp(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async uploadKKPImage({ dispatch }, { id, image }) {
    try {
      const formData = new FormData()
      formData.set('file', image)
      await AutoBankingService.uploadKKPImage(id, formData)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      return false
    }
  },
  async submitKTB({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.submitKTB(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async requestGSBOtp({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.requestGSBOtp(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async submitGSBOtp({ dispatch }, { id, data }) {
    try {
      await AutoBankingService.submitGSBOtp(id, data)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
    }
  },
  async uploadGSBImage({ dispatch }, { id, image }) {
    try {
      const formData = new FormData()
      formData.set('file', image)
      await AutoBankingService.uploadGSBImage(id, formData)
      dispatch('notifySuccess')
      return true
    } catch (e) {
      dispatch('notifyError', e)
      return false
    }
  },
}

const getters = {
}

const state = {

}

export default {
  state,
  mutations,
  actions,
  getters,
}
